import React, { useState, useCallback,useMemo, useEffect,useRef } from "react";
import ellipse from '../../../assets/Ellipse.png'
import discovery from '../../../assets/boxsearch.png'
import Icon_3 from '../../../assets/Icon (3).png'
import Icon_4 from '../../../assets/Icon (4).png'
import Icon_5 from '../../../assets/Icon (5).png'
import Icon_6 from '../../../assets/Icon (6).png'
import Icon_7 from '../../../assets/Icon (7).png'
import bell_1 from '../../../assets/bell (1).png'
import bell_3 from '../../../assets/bell (3).png'
import bell_4 from '../../../assets/bell (4).png'
import Icon_8 from '../../../assets/Icon (8).png'
import Icon_9 from '../../../assets/Icon (9).png'
import Icon_11 from '../../../assets/Icon@2x.png'
import edit from '../../../assets/Edit.png'
import delete_img from '../../../assets/Delete.png'
import file from '../../../assets/file.png'
import rightarrow from '../../../assets/rightarrow.png'
import liftarrow from '../../../assets/leftarrow.png'
import paypal from '../../../assets/Paypal.png'
import mastercard from '../../../assets/Mastercard.png'
import visa from '../../../assets/Visa.png'
import googlemap from '../../../assets/googlemap.png'
import location from '../../../assets/location.png'
import filter from '../../../assets/filter.png'
import frame from '../../../assets/Frame.png'
import xcelsz from '../../../assets/xcelsz.png'

// import { Calendar } from "@natscale/react-calendar";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { ToastContainer, toast } from 'react-toastify';


import {useDropzone} from 'react-dropzone';

import * as Yup from "yup";
import { useFormik,Field  } from "formik";

import axios from 'axios';
import { UploadButton } from "@bytescale/upload-widget-react";

import "./listOview.css";

import Pagination from './pagination';
let PageSize = 5;

const options = {
    apiKey: "free", //"public_FW25brM9kSSiy5Gd5VXC2qKy8vzi", // Get API key: https://www.bytescale.com/get-started
    maxFileCount: 1,
    mimeTypes: ["image/*"],
    editor: {
      images: {
        allowResizeOnMove: true,
        crop: true,
        // cropFilePath: ,
        cropRatio: 1.778,
        // cropShape: "circ",
        preview: true,
      },
    },
  };



function ListingOview() {
    const notify = (msg) => toast(msg);
    const [value, onChange] = useState(new Date());
    const [deal, setDeal] = useState('Occupy');
    const [loading, setLoading] = useState(false);
    const [mediaUrl, setMediaUrl] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [dealVisibility, setDealVisibility] = useState(false);
    
    const[data, setData] = useState([])
    const[listingTypesID, setListingTypesID] = useState([])


    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage,data]);

    
    function countLabels(data, label) {
        return data.filter(item => item.deal_type_id === label).length;
    }

    // function dealName(label) {
    //     console.log(JSON.parse(listingTypesID.filter(item => item._id === label)) );
    //     return JSON.parse(listingTypesID.filter(item => item._id === label)) 
    // }
    
    // const cdealCount = countLabels(jsonData, 'cdeal');
    // const kdealCount = countLabels(jsonData, 'kdeal')

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
    // const files = acceptedFiles.map(file => (
    //   <li key={file.path}>
    //     {file.path} - {file.size} bytes
    //   </li>
    // ));


    // const setImage = (url) => {
    //     if (url) {
    //       setMediaUrl(url);
    //     }
    //   };

    useEffect(()=>{
        console.log(mediaUrl);
    },[mediaUrl])
 

  const formik = useFormik({
    initialValues: {
      propertyName: "",
      propertyType: "",
      propertyCategory: "",
      propertySize: "",
      propertyPrice: "",
    //   propertyUse: "",
      room:"",
      beds:"",
      parkingSpace:"",
    //   furnishedStatus:"",
      residentialAddress:"",
      googleMapsAddress:"",
    //   propertyMediaFiles:"",
      summary:"",
      keywords:"",
    //   paymentMethod:""
    },
    validationSchema: Yup.object({
    propertyName:  Yup.string()
        .required("Property Name is required")
        .label("Property Name"),
    propertyType:  Yup.string()
        .required("Property Type is required")
        .label("Property Type"),
    propertyCategory:  Yup.string()
        .required("Property Category is required")
        .label("Property Category"),
    propertySize:  Yup.string()
        .required("Property Size is required")
        .label("Property Size"),
    propertyPrice:  Yup.string()
        .required("Property Price is required")
        .label("Property Price"),
    propertyUse:  Yup.string()
        // .required("Property Use is required")
        .label("Property Use"),
    room: Yup.string()
        .required("Room is required")
        .label("Room"),
    beds: Yup.string()
        .required("Beds is required")
        .label("Beds"),
    parkingSpace: Yup.string()
        .required("Parking Space is required")
        .label("Parking Space"),
    furnishedStatus: Yup.string()
        // .required("Furnished Status is required")
        .label("Furnished Status"),
    residentialAddress: Yup.string()
        .required("Email address is required")
        .label("Email address"),
    googleMapsAddress: Yup.string()
        .required("Google Maps Address is required")
        .label("Google Maps Address"),
    // propertyMediaFiles: Yup.string()
    //     .required("Property Media Files is required")
    //     .label("Property Media Files"),
    summary: Yup.string()
        .required("Summary is required")
        .label("Summary"),
    keywords: Yup.string()
        .required("Keywords is required")
        .label("Keywords")
    }),
    // paymentMethod: Yup.boolean()
    //     .oneOf(['credit', 'paypal','momo'], 'Payment method is required')
    //     .required('Payment method is required')
    //     .label("Payment method"),

    onSubmit: async (values, {resetForm}) => {
        
        setLoading(true)
        // console.log(acceptedFiles[0]);
        // const fileurl = onFileSelected(acceptedFiles[0])
        let dealType_id = ''
        if (deal == 'Occupy') {
            dealType_id='occupy'
        }else if (deal == 'Invest') {
            dealType_id='invest'
        }else{
            dealType_id='jv'
        }

        const uuid = Math.floor(Math.random() * 900) + 100;

        const formData = new FormData();
        formData.append('file', mediaUrl[0]);
         formData.append('propertyName',values.propertyName);
         formData.append('uuid',`list${uuid}`);
         formData.append('deal_type_id', dealType_id);
         formData.append('propertyType',values.propertyType);
         formData.append('propertyCategory',values.propertyCategory);
         formData.append('propertySize',values.propertySize);
         formData.append('propertyPrice',values.propertyPrice);
         formData.append('propertyUse','values.propertyUse');
         formData.append('room',values.room);
         formData.append('beds',values.beds);
         formData.append('parkingSpace',values.parkingSpace);
         formData.append('furnishedStatus','values.furnishedStatus');
         formData.append('residentialAddress',values.residentialAddress);
         formData.append('googleMapsAddress',values.googleMapsAddress);
        //  formData.append('propertyMediaFiles',values.propertyMediaFiles);
         formData.append('summary',values.summary);
         formData.append('keywords',values.keywords);
         formData.append('payment_mode','0');


        //  console.log(formData);
         for (let [key,value] of formData.entries()){
            console.log(`${key}: ${value}`);
         }
        try {
            
            const response = await axios.post(
              'https://ihubbackend2.xcelsz.com/api/listing/upload_listing',
              formData,{
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            );
            console.log(response.data);
            getallListiing()
            setDealVisibility(false)
            notify('Property Deal Created')
            resetForm()
          } catch (error) {
            toast.danger('Property Deal Creation Failed')
            setLoading(false)
            console.error('Error uploading file:', error);
          }
        setLoading(false)
    },
  });

  const getallListiing = async ()=>{
    try {
        const response = await axios.get(
          ' https://ihubbackend2.xcelsz.com/api/listing/get-all-deals');
        console.log(response.data);
        setData(response.data)
        setCurrentPage(1)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }

  const deleteListiing = async (id)=>{
    try {
        const response = await axios.delete(
          `https://ihubbackend2.xcelsz.com/api/listing/delete/${id}`);
        console.log(response.data);
        getallListiing()
        notify('card deleted')
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }

  const getallListiingTypes = async ()=>{
    try {
        const response = await axios.get(
          'https://ihubbackend2.xcelsz.com/api/feature/listingTypes');
        setListingTypesID(response.data)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }


  useEffect(()=>{
    getallListiing()
    getallListiingTypes()
  },[])

    return ( 
        <>
        <ToastContainer />
        {dealVisibility && 
        <div style={{ position:'fixed', height:'100vh', width:'100%', background:'rgba(0,0,0,.4)',zIndex:9999, top:0, left:0, right:0, button:0 }}>
            <div style={{ background:'white', width:'50%', height:'100%', margin:'2rem auto', borderRadius:'1rem', padding:'2rem', overflowY:'scroll' }}>
                <form style={{ marginBottom:'2rem' }} onSubmit={formik.handleSubmit}>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                        <h2>{`Add New ${deal} Deal`}</h2>
                        <div onClick={(prev)=>{setDealVisibility(false)}} style={{ background:'#FBF6E4', width:40, height:40, borderRadius:'50%',display:'flex', alignItems:'center', justifyContent:'center' }}>
                            <h2 style={{ cursor:'pointer' }}>x</h2>
                        </div>
                    </div>
                    <span style={{ padding:'3rem 0' }}>General Information</span>
                    
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Name</label>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyName} type="text" name="propertyName" id="propertyName" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Enter property name" />
                        {formik.touched.propertyName && formik.errors.propertyName ? (
                            <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyName}</p>
                        ) : null}
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'1rem' }}>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Type</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyType} type="text" name="propertyType" id="propertyType" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Select  property type. eg;Apartment" />
                            {formik.touched.propertyType && formik.errors.propertyType ? (
                            <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyType}</p>
                        ) : null}
                        </div>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Category</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyCategory} type="text" name="propertyCategory" id="propertyCategory" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Select category. eg;B2S" />
                            {formik.touched.propertyCategory && formik.errors.propertyCategory ? (
                            <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyCategory}</p>
                        ) : null}
                        </div>
                    </div>
                    
                    <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'3rem' }}>
                        <div style={{ flexDirection:'row', display:'flex', width:'50%',gap:'1rem' }}>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Porperty Size ( m² )</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertySize} type="number" name="propertySize" id="propertySize" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.propertySize && formik.errors.propertySize ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertySize}</p>
                                ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Price ( USD )</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyPrice} type="number" name="propertyPrice" id="propertyPrice" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                            {formik.touched.propertyPrice && formik.errors.propertyPrice ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyPrice}</p>
                                ) : null}
                            </div>
                           
                        </div>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Use</label>
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="" id="" style={{ margin:'1rem' }} />
                                    <p>Commercial</p>
                                </div>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="" id="" style={{ margin:'1rem' }}/>
                                    <p>Residential</p>
                                </div>



                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'3rem' }}>
                        <div style={{ flexDirection:'row', display:'flex', width:'50%',gap:'1rem' }}>
                            <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Rooms</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.room} type="number" name="room" id="room" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.room && formik.errors.room ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.room}</p>
                                ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Beds</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.beds} type="number" name="beds" id="beds" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.beds && formik.errors.beds ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.beds}</p>
                                ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Parking Space</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.parkingSpace} type="number" name="parkingSpace" id="parkingSpace" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                            {formik.touched.parkingSpace && formik.errors.parkingSpace ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.parkingSpace}</p>
                                ) : null}
                            </div>
                           
                        </div>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Furnished Status</label>
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="" id="" style={{ margin:'1rem' }} />
                                    <p>Yes</p>
                                </div>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="" id="" style={{ margin:'1rem' }}/>
                                    <p>No</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Residential Address</label>
                        <div style={{ position:'relative', width:'100%' }}>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.residentialAddress} type="text" name="residentialAddress" id="residentialAddress" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', width:'100%' }} placeholder="Enter residential address" />
                            <img src={location} width={20} height={20} alt="" style={{ position:'absolute',right:'1rem', top:'2rem' }}/>
                        </div>
                        {formik.touched.residentialAddress && formik.errors.residentialAddress ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.residentialAddress}</p>
                            ) : null}
                      
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Google Maps Address</label>
                        <div style={{ position:'relative', width:'100%' }}>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.googleMapsAddress} type="text" name="googleMapsAddress" id="googleMapsAddress" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', width:'100%' }} placeholder="Enter google maps address" />
                            <img src={googlemap} width={25} height={25} alt="" style={{ position:'absolute',right:'1rem', top:'1.5rem' }}/>
                        </div>
                        {formik.touched.googleMapsAddress && formik.errors.googleMapsAddress ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.googleMapsAddress}</p>
                            ) : null}
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Media Files</label>
                        <section className="container">
                        <UploadButton
                            options={options}
                            onComplete={(files) =>
                                setMediaUrl((prev)=>([...prev,files.map((x) => x.fileUrl).join("\n")]))
                            }
                        >
                            {({ onClick }) => (
                            // <li title="add image">
                            //     <img src={edit} onClick={onClick} alt="gallery" />
                            // </li>
                            <div onClick={onClick}>
                            {/* <div onClick={onClick} {...getRootProps({className: 'dropzone'})}> */}
                                {/* <input {...getInputProps()}  /> */}
                                <div style={{ padding:'2rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'center' }}>
                                    <img width={70} height={25} src={frame} alt="" style={{ margin:'1rem' }} />
                                    <p ><span style={{ color:'#FAC710' }}>Click here </span>to upload or drop files here</p>
                                </div>
                            </div>
                
                    )}
                        </UploadButton>
                        {/* <div onClick={onClick} {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()}  />
                            <div style={{ padding:'2rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'center' }}>
                                <img width={70} height={25} src={frame} alt="" style={{ margin:'1rem' }} />
                                <p ><span style={{ color:'#FAC710' }}>Click here </span>to upload or drop files here</p>
                            </div>
                        </div> */}
                            
                            {mediaUrl.length > 0 && <aside>
                                <h4>Files</h4>
                                <div style={{display:'flex', alignItems:'center',justifyContent:'space-between',flexWrap:'wrap'}}>
                                    {mediaUrl.map((val,index)=>{
                                        return(
                                        <ul style={{position:'relative', padding:'.5rem 1rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'left', display:'flex', alignItems:'center', width:200 }}><img width={'100%'} height={100} src={val} alt="" /> <span style={{ position:'absolute', right:'1rem', fontSize:'1rem', fontWeight:'bold' }}>x</span></ul>
                                        )
                                    })}
                                </div>
                                
                                
                                {/* <ul style={{position:'relative', padding:'.5rem 1rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'left', display:'flex', alignItems:'center' }}><img width={25} height={25} src={file} alt="" />{files} <span style={{ position:'absolute', right:'1rem', fontSize:'1rem', fontWeight:'bold' }}>x</span></ul> */}
                            </aside>}
                            
                            </section>
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Deal Summary</label>
                        <p style={{ fontSize:'12px' }}>A short summary listing the key features and amenities of the deal</p>
                        <textarea onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.summary} type="text" name="summary" id="summary" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} />
                        {formik.touched.summary && formik.errors.summary ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.summary}</p>
                            ) : null}
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Keywords</label>
                        <div style={{ flexDirection:'row', display:'flex', gap:'1rem' }}>
                        {keywords.map(()=>{
                            <p style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:100 }}>Eco-Friendly <span style={{ marginLeft:'1rem' }}>X</span></p>
                        })}
                
                        {/* <p style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:100 }}>Luxury Home <span style={{ marginLeft:'1rem' }}>X</span></p> */}
                        </div>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.keywords} type="text" name="keywords" id="keywords" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Enter or Select keywords. eg; verified , affordable, unregistered, quality assured" />
                        {formik.touched.keywords && formik.errors.keywords ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.keywords}</p>
                            ) : null}
                    </div>

                    <label htmlFor="">Payment Method Options</label>
                    <div style={{ display:'flex', alignItems:'center' }}>
              
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.paymentMethod === 'credit'} type="radio" name="paymentMethod" id="paymentMethod" style={{ margin:'1rem' }} />
                        <p>Credit card or Debit card</p>
                        <img width={70} height={25} src={mastercard} alt="" style={{ margin:'1rem' }} />
                        <img width={70} height={25} src={visa} alt="" style={{ margin:'1rem' }} />
                    </div>
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.paymentMethod === 'paypal'} type="radio" name="paymentMethod" id="paymentMethod" style={{ margin:'1rem' }} />
                        <p>PayPal</p>
                        <img width={70} height={25} src={paypal} alt="" style={{ margin:'1rem' }} />
                    </div>

                    {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.paymentMethod}</p>
                            ) : null}

                    <div className="forms-btn" style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between' }}>
                        <button type="button" className="cancel">Cancel</button>
                        {!loading?<button type="submit" className="save">Save</button>:<button disabled type="submit" className="save">Loading ...</button>}
                        
                    </div>
                </form>
            </div>
        </div>
        }
        <div style={{maxWidth:'90%', margin:'0 auto', position:'relative'}}>
        
        <div className="header">
            <div className="logo">
                {/* <div></div> */}
            </div>
            <div className="right-side-header">
                <div className="icons" style={{marginRight:'3rem'}}>
                <img style={{margin:'0 1rem'}} width={20} height={20} src={bell_1} alt="" />
                <img style={{margin:'0 1rem'}} width={20} height={20} src={bell_4} alt="" />
                   
                </div>
                <div className="icons" >
                    <img width={40} height={40} src={ellipse} alt="" />
                </div>
            </div>
        </div>
        <div className="work-space" >
            <h2>Work Space</h2>
        </div>
        <div className="main-overview" >
            <div className="left-menu" >
                <ul>
                    <li><img width={20} height={20} src={Icon_6} className="icon" alt=""/>Work Space Overview</li>
                    <li><img width={20} height={20} src={Icon_9} className="icon" alt=""/>Sprint Board</li>
                    <li><img width={20} height={20} src={bell_3} className="icon" alt=""/>Team Backlog</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>Compliance</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>User Experience (UX)</li>
                    <li><img width={20} height={20} src={Icon_7} className="icon" alt=""/>Leads</li>
                    <li className='active'><img width={20} height={20} src={Icon_4} className="icon" alt=""/>Listing <span className='total-tag'>{data.length}</span></li>
                    <li><img width={20} height={20} src={Icon_3} className="icon" alt=""/>Ouality</li>
                    <li><img width={20} height={20} src={discovery} className="icon" alt=""/>Discovery</li>
                    <li><img width={20} height={20} src={Icon_11} className="icon" alt=""/>Maintenance</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>Governance</li>
                    <li><img width={20} height={20} src={Icon_8} className="icon" alt=""/>Security</li>
                </ul>
            </div>
            <div className="right-blog">
                <div className='listing-overview'>
                <div className="top-section">
                    <h1>Listing Overview</h1>
                    <div className="check">
                    <span style={{marginBottom:'2rem'}}>Health Check</span>
                    <div style={{ display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <h2>30 Published</h2>
                    <span>10 In Progess </span>
                    
                    </div>
                    </div>
                    <div className='' style={{margin:'1rem 2rem'}}>
                    <div className='process-bar'><div className='process'></div></div>
                    </div>
                    
                </div>
                <div className="down-section" style={{ padding:'2rem' }}>
                    <h3>Listing Summary</h3>
                    <div className='tags-container'>
                        <div className='tags'>
                            <span>total</span>
                            <p className='number'>{data.length}</p>
                        </div>
                        <div className='tags'>
                            <span>Occupy Deals</span>
                            <p className='number'>{countLabels(data, 'occupy')}</p>
                        </div>
                        <div className='tags'>
                            <span>invest Deals</span>
                            <p className='number'>{countLabels(data, 'invest')}</p>
                        </div>
                        <div className='tags'>
                        <span>Joint Venture (JV) Deals  </span>
                            <p className='number'>{countLabels(data, 'jv')}</p>
                        </div>
                        {/* <div className='tags'>
                            <span>Deal Of the Month</span>
                            <p className='number'>9</p>
                        </div> */}
                        <div className='tags'>
                            <span>archived</span>
                            <p className='number'>{countLabels(data, 'archived')}</p>
                        </div>
                        {/* <div className='tags'>
                            <span>deleted</span>
                            <p className='number'>0</p>
                        </div> */}
                        {/* <div className='tags'>
                            <span>draft</span>
                            <p className='number'>5</p>
                        </div> */}
                    </div>
                </div>

                </div>
                <div className='create-deal-listing'>
                <div className="top-section">
                    <h1>Create Deals Listings</h1>
                    <div className='deals-tags'>
                        <h3 onClick={()=>{setDeal('Occupy')}} className={deal === 'Occupy'?`active`:``}>Occupy Deals</h3>
                        <h3 onClick={()=>{setDeal('Invest')}} className={deal === 'Invest'?`active`:``}>Invest Deals</h3>
                        <h3 onClick={()=>{setDeal('JV')}} className={deal === 'JV'?`active`:``}>JV Deals</h3>
                    </div>
                    <div className='cta-deal'>
                        <div className='wrapper'>
                        <p>Create New Property Occupancy Deal</p>
                        <button onClick={(prev)=>{setDealVisibility(true)}}>Create Deal</button>
                        </div>
                    </div>
                    
                </div>
                <div className="down-section">
                    <h3>Solution Platforms</h3>
                    <div className='platfroms'>
                    <img src={xcelsz} width={50} height={50} alt="" style={{ borderRadius:'50%', margin:'1rem', border:'2px solid #fac710' }}/>
                    </div>
                </div>

                </div>
                <div className='all-deal-listing'>
                <div className="top-section">
                    <h1>All Deals Listings</h1>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{padding:'1rem'}}>
                        <Calendar
                            onChange={onChange}
                            value={value}
                            className='calendar'
                        />
                    </div>
                    <div style={{padding:'1rem'}}>
                        <input type="text" name="" id="" style={{ padding:'.6rem 1rem', borderRadius:'.5rem', margin:'1rem 0', border:'2px solid #D3CFC0', fontSize:'1rem', width:400 }} placeholder="Search" />
                        <div style={{ flexDirection:'row', display:'flex', gap:'1rem', flexWrap:'wrap', alignItems:'center',width:400 }}>
                            <img src={filter} width={25} height={25} alt=""/>
                            <p style={{ padding:'.3rem .5rem', borderRadius:'.5rem', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:50 }}>All <span style={{ marginLeft:'1rem' }}>X</span></p>
                            <p style={{ padding:'.3rem .5rem', borderRadius:'.5rem', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:50 }}>Mavis <span style={{ marginLeft:'1rem' }}>X</span></p>
                            <p style={{ padding:'.3rem .5rem', borderRadius:'.5rem', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:50 }}>All <span style={{ marginLeft:'1rem' }}>X</span></p>
                            <p style={{ padding:'.3rem .5rem', borderRadius:'.5rem', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:50 }}>Mavis <span style={{ marginLeft:'1rem' }}>X</span></p>
                            <p style={{ padding:'.3rem .5rem', borderRadius:'.5rem', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:50 }}>All <span style={{ marginLeft:'1rem' }}>X</span></p>
                            <p style={{ padding:'.3rem .5rem', borderRadius:'.5rem', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:50 }}>Mavis <span style={{ marginLeft:'1rem' }}>X</span></p>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%', padding:'1rem'}}>
                    <table style={{width:'100%', borderRadius:'1rem',background:'#fbf6e4'}}>
                        <tr >
                            <th style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>ID</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Creator</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Listing Type</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Listing Name</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}> Action</th>
                        </tr>
                        {currentTableData.map((val, key) => {
                            return (
                                <tr key={key} style={{background:'#fffff'}}>
                                    <td style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></td>
                                    <td style={{textAlign:'center',padding:'.5rem'}}>{val.uuid}</td>
                                    <td style={{textAlign:'center',padding:'.5rem'}}>{'admin'}</td>
                                    <td style={{textAlign:'center',padding:'.5rem'}}>{val.deal_type_id}</td>
                                    <td style={{textAlign:'center',padding:'.5rem'}}>{val.propertyName}</td>
                                    <td style={{textAlign:'center',padding:'.5rem', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                        <button>Publish</button>
                                        <img style={{margin:'0 1rem',cursor:'pointer'}} width={20} height={20} src={edit} alt="" />
                                        <img onClick={()=>{deleteListiing(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" />

                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                    <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={data.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    {/* <div className="paginate">
                        <button> <img style={{margin:'0 1rem'}} width={10} height={10} src={liftarrow} alt="" />Back</button>
                        
                        <div style={{ margin:'1rem', display:'flex', alignItems:'center', justifyContent:'space-between',width:'auto' }}>
                            <p className="active">1</p>
                            <p>2</p>
                            <p>3</p>
                        </div>
                        <button> Next <img style={{margin:'0 1rem'}} width={10} height={10} src={rightarrow} alt="" /></button>
                    </div> */}
                </div>

                </div>

            </div>
        </div>

        <div className="footer" style={{ background:'black', width:'100%', padding:'1rem', marginTop:'2rem' }}>
            <h2 style={{ textAlign:'center', color:'white' }}>Collaborate with Team Mates, Any Where, Any Time.</h2>
        </div>
        
        </div>
        
        </>
     );
}

export default ListingOview;