// import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginService from "../../https/login/loginHttp";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./LoginFormContainer.css";

const LoginFormContainer = ({notification, updateEmail,updateSection,updateUserData,sendcode}) => {
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  
  const handleLogin = async (data) => {
    setLoading(true)
    try {
      const res = await loginService.login(data);
      console.log(res);
     
      updateUserData(res.user)
      setLoading(false)
      notification("Welcome Login Successfull")
      // navigate("/listing");
      updateEmail(data.email)
      updateSection('opt')
      // navigate(`/validate/${data.email}`);
      sendcode()
      
    } catch (error) {
      // let res = error.response["data"];
      // setErrorMsg(res["error"]);
      // setLoginError(true);
      setLoading(false)
      notification("Invalid credentials")
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email address must be valid")
        .matches(/^[A-Za-z0-9._%+-]+@xcelsz\.com$/, 'Email must be from @xcelsz.com')
        .required("Email address is required")
        .label("Email address"),
      password: Yup.string()
        .min(8, "Password should be a minimum of 8 characters")
        .required("Password is required")
        .label("Password"),
    }),
    onSubmit: (values) => {
      console.log(values);
      handleLogin(values);
    },
  });
  return (
    <>
      {loginError && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "13px",
            marginLeft: "3px",
            marginBottom: "0",
            fontFamily: "SFmedium",
          }}
        >
          {errorMsg}
        </p>
      )}
      <form id="login-forms" onSubmit={formik.handleSubmit}>
        <h2 style={{fontSize:'1.3rem'}}>Log In</h2>
        <div className="input-container">
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Work Email</label>
          {formik.touched.email && formik.errors.email ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.email}
          </p>
        ) : null}
          </div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            type="email"
            placeholder="Enter Email"
            id="email"
          />
        </div>
        
        <div className="input-container">
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Password</label>
          {formik.touched.password && formik.errors.password ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.password}
          </p>
        ) : null}
          </div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            type="password"
            placeholder="Enter Password"
            id="password"
          />
        </div>
        <div className="checkbox-container">
          <label style={{marginRight:'5px', fontSize:'14px'}}>Keep me Login</label>
          <input type="checkbox" id="checkbox" />
          <label for="checkbox" class="custom-checkbox"></label>
        </div>
        {loading?
        <button type="button" disabled className="loading-button">
        Authenticating ....
      </button>
        :
        <button type="submit" className="login-button">
          Log in
        </button>
        }
        
        
        <span class="recover-password" >Forgot Password?</span>
      </form>
    </>
  );
};

export default LoginFormContainer;
