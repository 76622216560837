import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/loginPage";
import Reset from "./pages/login/ResetPassword";
import EmailValidate from "./pages/login/EmailVerification"
import ListingOview from "./pages/dashboard/listing/ListingOview";

import ProtectedRoute from "./protectedRoute";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="listing" element={<ProtectedRoute><ListingOview /></ProtectedRoute>}/>
      {/* <Route path="/" element={<ListingOview />} /> */}
      <Route path="/" element={<LoginPage />} />
      <Route path="validate/:id" element={<EmailValidate />} />
      {/* <Route path="*" element={<NoPage />} /> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
